import React, { Component } from 'react'
import { connect } from 'react-redux'
import StartNewPanel from 'start-new-panel'
import PhoneComUser from 'phone-com-user'
import api from '../util/api_v5.js'
import { switchView } from '../actions/view'
import PhoneIcon from '@material-ui/icons/Phone'
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing'
import { withStyles } from "@material-ui/core"

const styles = theme => ({
	contentWrapper: {
		padding: 30
	},
	placeCallWrapper: {
		display:	'flex',
		alignItems:	'center',
		fontSize:	25,
		fontWeight:	500,
		cursor:		'pointer',
		width:		'fit-content',
		padding:	'5px 20px 5px 10px',
		'&:hover': {
			background:	'#f1f1f1',
			color:		theme.palette.primary.main
		}
	},
	phoneIcon: {
		color:			theme.palette.primary.main,
		marginRight:	10,
		fontSize:		50
	},
	callMadeInfo: {
		display:		'flex',
		flexDirection:	'column',
		'& span': {
			marginTop:	20,
			fontSize:	16,
			fontWeight:	'bold'
		}
	},
	phoneRingingIcon: {
		fontSize: 100
	}
})

const mapStateToProps = state => ({
	smallView: state.smallView
})

const mapDispatchToProps = dispatch => ({
	switchView: view => dispatch(switchView(view))
})

class MakeCall extends Component {

	state = {
		callee:		null,
		caller:		null,
		callIsMade:	false
	}

	componentDidMount() {
		this.setState({caller: this.props.extension.caller_id})
		//add do not focus if iOS use os-detector
		//document.getElementById('tags-input').focus()
	}

	makeCall = () => {
		this.setState({callIsMade: true})
		let extension			= this.props.extension
		let callerExtension		= extension.extension_id
		let calleePhoneNumber	= this.state.callee
		let callerCallerId		= this.state.caller // Not sure what to put
		let calleeCallerId		= this.state.caller
		api.makeCall(callerExtension, calleePhoneNumber, callerCallerId, calleeCallerId)
	}

	updateCallee = recipients => this.setState({callee: recipients.length ? recipients[0].number : null})

	updateFromNumber = phoneNumber => this.setState({caller: phoneNumber})

	render() {
		const { classes }	= this.props
		let phoneNumbers	= PhoneComUser.getPhoneNumber()
		let inputNotAllowed	= Boolean(phoneNumbers === undefined || phoneNumbers.length === 0)
		return (
			<StartNewPanel
				extension						= {this.props.extension}
				contactsUtil					= {this.props.contactsUtil}
				startChatButton					= {null}
				startChatButtonDisabled			= {null}
				singleContact					= {true}
				showDialpad						= {true}
				renderChildrenIfContactSelected	= {true}
				addRecipientsAsYouType			= {true}
				showSendToField					= {false}
				allowShortCodes					= {false}
				inputNotAllowed					= {inputNotAllowed}
				goBackText						= 'New Call'

				updateContacts					= {this.props.updateContacts}
				goBack							= {() => this.props.switchView('select')}
				updateRecipients				= {this.updateCallee}
				updateFromNumber				= {this.updateFromNumber}
			>
				<div className={classes.contentWrapper}>
					{this.state.callIsMade ?
						<div className={classes.callMadeInfo}>
							<PhonelinkRingIcon classes={{root: classes.phoneRingingIcon}}/>
							<span>
								Your cell phone should be ringing now.<br/>
								Answer the call on your mobile app,<br/>
								and we'll connect you to the recipient.
							</span>
						</div>
					:
						<div className={classes.placeCallWrapper} onClick={this.makeCall}>
							<PhoneIcon classes={{root: classes.phoneIcon}}/>
							<span>Place Call</span>
						</div>
					}
				</div>
			</StartNewPanel>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MakeCall))