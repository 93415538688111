import React, { Component } from 'react'
import Messages from 'messages'
import Voicemail from 'voicemail'
import Settings from 'settings'
import Call from 'calls'
import Faxes from 'faxes'
import Navigation from './src/nav/Navigation'
import AppLoader from './src/AppLoader.js'
import api from './src/util/api';
import Api from 'api'
import { loadContacts, loadMore, loadExtraContacts, updateExtraContacts, updateContact, deleteContact } from './src/util/contacts'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles'
import FirstTimeUserInfoPopupContent from './src/temp/FirstTimeUserInfoPopupContent.js'
import NotificationBar from './src/NotificationBar.js'
import { initializePhoneCom, getAppConfig } from 'phonecom'
import InfoPopup from 'info-popup'
import PDCOpenConnection from 'pdc-open-connection'
import { pushMessageNotification, pushVoicemailNotification, pushFaxNotification } from 'notification-pusher'
import PhoneComUser from 'phone-com-user'
import { theme } from 'get-theme'
import { withStyles } from '@material-ui/core'
const themeFontFamily = theme.fontFamily || 'ProximaNova, Helvetica, arial, sans-serif !important'
const styles = theme => ({
	mainDiv: {
		height:			'100%',
		display:		'flex',
		flexDirection:	'column',
		'& *': {
			fontFamily:	themeFontFamily
		}
	}
})

class Communicator extends Component {

	constructor(props) {
		super(props);
		this.infoPopupContent = <FirstTimeUserInfoPopupContent/>;
		this.state = {
			selectedExtension:	null,
			contactsInfo: {
				contacts:		null,
				extraContacts:	[],
				groupTypes:		[]
			},
			loadingContacts:	false,
			loading:			true,
			appLoading:			true,
			screenViewType: {
				isMobileView: false,
				isTabletView: false,
			},
			unreadMessages:			null,
			showNotificationBar:	false,
			currentAppName:			'calls'
		}

		this.notificationSubscriptions = {
			messages:		[this.setUnreadCounts.bind(this, null)],
			voicemail:		[this.setUnreadCounts.bind(this, null)],
			faxes:			[this.setUnreadCounts.bind(this, null)],
			read_status:	[this.setUnreadCounts.bind(this, null)] // This is about messages
		}
		PDCOpenConnection.onReconnect((retryingConnection)=>{
			if(!retryingConnection) {
                window.location.reload();
            }
		})
	}

	setUnreadCounts = extensionId => {
		let ext = extensionId || this.state.selectedExtension.extension_id
		api.getUnreadCounts(ext).then(
			res => {
				let unread = res.items[0]
				this.setState({
					unreadMessages:		unread.messages,
					unreadVoicemails:	unread.voicemails,
					unreadFaxes:		unread.faxes
				})
			}
		)
	}

	changeMessageReadStatus = (type, howMany) => {
		if (howMany === 'all') {
			this.setState({unreadMessages: 0})
		} else if (type === 'read') {
			this.setState({unreadMessages: this.state.unreadMessages - howMany})
		} else if (type === 'unread') {
			this.setState({unreadMessages: this.state.unreadMessages + howMany})
		} else {
			console.error('Invalid type for changing message read status')
		}
	}

	changeVoicemailReadStatus = (type, howMany) => {
		if (howMany === 'all') {
			this.setState({unreadVoicemails: 0})
		} else if (type === 'read') {
			this.setState({unreadVoicemails: this.state.unreadVoicemails - howMany})
		} else if (type === 'unread') {
			this.setState({unreadVoicemails: this.state.unreadVoicemails + howMany})
		} else {
			console.error('Invalid type for changing voicemail read status')
		}
	}

	changeFaxReadStatus = (type, howMany) => {
		if (howMany === 'all') {
			this.setState({unreadFaxes: 0})
		} else if (type === 'read') {
			this.setState({unreadFaxes: this.state.unreadFaxes - howMany})
		} else if (type === 'unread') {
			this.setState({unreadFaxes: this.state.unreadFaxes + howMany})
		} else {
			console.error('Invalid type for changing fax read status')
		}
	}

	setScreenView(){
		let window_size = window.innerWidth;
		console.log(this.props.theme.screenViewSizes.mobileViewSize);
		if(this.props.theme.screenViewSizes.mobileViewSize<window_size && window_size<this.props.theme.screenViewSizes.tabletViewSize) {
			this.updateScreenViewState({
				'screenViewType':
					{
						'isTabletView': true,
						'isMobileView': false
					}
			});

		}else if(window_size<this.props.theme.screenViewSizes.mobileViewSize){
						console.log('mobile');

			this.updateScreenViewState({
				'screenViewType':
					{
					'isTabletView': false,
					'isMobileView': true
				}
			});
		}else{
			this.updateScreenViewState({
				'screenViewType':
					{
						'isTabletView': false,
						'isMobileView': false
					}
			});
		}

	}


	updateScreenViewState(screenViewState){
		let currentScreenViewState = this.state.screenViewType;

		if(screenViewState.screenViewType.isMobileView !== currentScreenViewState.isMobileView ||
			screenViewState.screenViewType.isTabletView !== currentScreenViewState.isTabletView){
			console.log('updating');
			this.setState(screenViewState)
		}
	}


	componentWillMount() {
		this.setScreenView();
		window.addEventListener('resize', ()=> this.setScreenView());
	}


	componentDidMount = () => {
		this.init();
	}

	initNotifications = () => {
		let onVoicemailNotification = notification => {

			let voicemail = notification
			let extensionId	= this.state.selectedExtension.extension_id

			if (!['read_status', 'delete'].includes(voicemail.type)) pushVoicemailNotification(voicemail, extensionId)

			this.notificationSubscriptions.voicemail.forEach(subscription => subscription(notification))
		}
		
		let onFaxNotification = notification => {

			let fax = notification
			let extensionId	= this.state.selectedExtension.extension_id

			if (!['read_status', 'delivery_status', 'delete'].includes(fax.type)) pushFaxNotification(fax, extensionId)

			this.notificationSubscriptions.faxes.forEach(subscription => subscription(notification))
		}

		let onMessageNotification = notification => {

			let message		= notification
			let extensionId	= this.state.selectedExtension.extension_id

			if (!['read_status', 'delete'].includes(message.type)) message = message.details
			if (message.type !== 'read_status') pushMessageNotification(message, extensionId)

			this.notificationSubscriptions.messages.forEach(subscription => subscription(notification))
		}

		let onReadStatusNotification = notification => {
			this.notificationSubscriptions.read_status.forEach(subscription => subscription(notification))
		}

		PDCOpenConnection.on('voicemail', onVoicemailNotification)
		PDCOpenConnection.on('fax', onFaxNotification)
		PDCOpenConnection.on('messages', onMessageNotification)
		PDCOpenConnection.on('read_status', onReadStatusNotification)
	}

	resetSubscription = isForce => {
		if (!PDCOpenConnection.connected) PDCOpenConnection.hardReset()
	}

	subscribeForNotifications = (type, callback, reinitialize=false) => {
		if (type === 'messages') {
			if (reinitialize) this.notificationSubscriptions.messages = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.messages.push(callback)
		} else if (type === 'voicemail') {
			if (reinitialize) this.notificationSubscriptions.voicemail = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.voicemail.push(callback)
		} else if (type === 'fax') {
			if (reinitialize) this.notificationSubscriptions.faxes = [this.setUnreadCounts.bind(this, null)]
			this.notificationSubscriptions.faxes.push(callback)
		} else if (type === 'read_status') {
			this.notificationSubscriptions.read_status.push(callback)
		}
	}

	componentDidUpdate = () => {
		this.fixUrlPath()
		if (this.state.userInfo && !this.state.selectedExtension) {
			let phoneNumbers = Object.keys(this.state.userInfo.extension.phone_number)
			PhoneComUser.changePhoneNumber(phoneNumbers)
			this.setState({selectedExtension: this.state.userInfo.extension})
		}
		this.setFavicon()
	}

	setFavicon = () => {
		if (this.state.unreadMessages || this.state.unreadVoicemails || this.state.unreadFaxes) {
			if (document.getElementsByName('favicon')[0].href !== theme.favicon.unread) {
				document.getElementsByName('favicon')[0].href = theme.favicon.unread
			}
		} else {
			if (document.getElementsByName('favicon')[0].href !== theme.favicon.default) {
				document.getElementsByName('favicon')[0].href = theme.favicon.default
			}
		}
		if (document.getElementsByName('apple-icon')[0].href !== theme.favicon.default) {
			document.getElementsByName('apple-icon')[0].href = theme.favicon.default
		}
		if (document.getElementsByName('apple-icon')[1].href !== theme.favicon.default) {
			document.getElementsByName('apple-icon')[1].href = theme.favicon.default
		}
	}


	//todo rework and remove this. This should be in the init phone come util not here
	init = async () => {
		let config = await getAppConfig()
		window.APP_CONFIG = config
		let v5phonecom = {
			stage:			config.stage,
			v4ApiRoot:		config.v4ApiRoot,
			v5ApiRoot:		config.v5ApiRoot,
			v5ToolsRoot:	config.v5ToolsRoot,
			redirect_url:	config.redirect_url
		}
		window.V5PHONECOM = v5phonecom
		await this.initialLoad()
	}

	initialLoad = async config => {
		let res = await initializePhoneCom(config)
		if (!res) return
		if(window.Rollbar) {
            window.Rollbar.configure({
                payload: {
                    person: {
                        id: res.account_id, // required
                        voip_id: res.account_id,
                        voip_phone_id: res.extension_id,
                        account_name: res.account.name,
                    },
					fullstory_url: (window.FS)? window.FS.getCurrentSessionURL(true) : null
                }
            });
        }

		console.log(`APP_CONFIG: ${JSON.stringify(window.APP_CONFIG)}`)
		console.log('ok we\'re ready!')

		this.setSelectedExtensionFromUrl(res)

		this.fixUrlPath(res.extension.extension_id)

		//added a 5sec timeout for the loader cover, incase the the app does not do a call back for when it is done loading
		setTimeout(() => {
			if (!this.state.appLoading) return
			this.setState({appLoading: false})
			this.checkShowNotificaitionBar()
		}, 5000)

		this.setUnreadCounts(res.extension.extension_id)

		let phoneNumbers = Object.keys(res.extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)
		this.setState({
			loading: false,
			userInfo: res,
			selectedExtension: res.extension,
		});
		this.initNotifications()
		loadContacts(this)
	}

	setSelectedExtensionFromUrl = res => {
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split('/')
		pathnameSplit.shift()
		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) return
		let extensionId = parseInt(pathnameSplit[0].substring(1))
		let extensionElement = res.extensions.find(e => e.extension_id === extensionId)
		if (!extensionElement) {
			return window.history.replaceState('Extension Id', 'Reset the extension id to default', `/e${res.extension_id}`)
		}
		res.extension = JSON.parse(JSON.stringify(extensionElement))
		res.extension_id = extensionElement.extension_id
	}

	isExtensionPathElement = s => {
		return s[0] === 'e' && !isNaN(s.substring(1))
	}


	fixUrlPath = extension_id => {
		if (!this.state.userInfo) return
		let ext_id = extension_id || this.state.userInfo.extension_id
		let pathname = window.location.pathname
		let pathnameSplit = pathname.split('/').filter(e => e)

		if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) {
			pathname = `/e${ext_id}/${pathnameSplit.join('/')}`
		}

		if ([0, 1].includes(pathnameSplit.length)) {
			if (pathname[pathname.length - 1] !== '/') pathname += '/'
			pathname += theme.defaultRoute
		}

		window.history.replaceState('Extension Id', 'Add missing extension id', pathname)
	}


	getAppsHeight = () => `calc(100% - ${this.mobileAppBarSpacer()})`


	mobileAppBarSpacer() {
		if (this.state.screenViewType.isMobileView || this.state.screenViewType.isTabletView) {
			return `${this.props.theme.appBarHeight}px`
		}
		return '0px'
	}

	onExtensionSwitch = extension => {

		let userInfo			= this.state.userInfo;
		let extensionElement	= userInfo.extensions.find(e => e.extension_id === extension.extension_id)
		userInfo.extension		= extensionElement
		userInfo.extension_id	= extensionElement.extension_id

		let phoneNumbers = Object.keys(extension.phone_number)
		PhoneComUser.changePhoneNumber(phoneNumbers)

		let pathnameSplit		= window.location.pathname.split('/').filter(e => e)
		pathnameSplit.shift()
		window.history.replaceState('Extension', 'Switched extension', `/e${extensionElement.extension_id}/${pathnameSplit.join('/')}/`)
		window.location.reload()

		// this.setState({selectedExtension: extension, userInfo})
		// this.setUnreadCounts(extension.extension_id)
		// PDCOpenConnection.hardReset()
	}

	redirect = redirectPath => this.setState({redirectPath})

	logout = () => {
		this.setState({userInfo: null})

		window.APP_CONFIG.cp_session_id	= ''
		window.V5PHONECOM.cp_token		= ''

		// TODO: Redirect to login
	}

	onAppLoaded = () => {
		if (this.state.appLoading) this.checkShowNotificaitionBar()
		this.setState({appLoading: false})
	}

	getCookie = cname => {
		let name = cname + '='
		let decodedCookie = decodeURIComponent(document.cookie)
		let ca = decodedCookie.split(';')
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i]
			while (c.charAt(0) === ' ') {
				c = c.substring(1)
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length)
			}
		}
		return ''
	}

	checkShowNotificaitionBar = () => {
		let cookieValue					= this.getCookie('mpdcdafn')
		let enabledNotificationBarShow	= cookieValue !== '1' && window.Notification && Notification.permission === 'default'
		return enabledNotificationBarShow ? this.setState({showNotificationBar: true}) : null
	}

	hideNotificationBar = () => this.setState({showNotificationBar: false})

	returnApp = (currentApp, currentAppName) => {
		if (this.state.currentAppName !== currentAppName) this.setState({currentAppName})
		return currentApp
	}

	getContactsUtil = () => {
		return {
			contacts:				this.state.contactsInfo.contacts,
			extraContacts:			this.state.contactsInfo.extraContacts,
			groupTypes:				this.state.contactsInfo.groupTypes,
			reload:					() => loadContacts(this),
			loadMore:				() => loadMore(this),
			getContactsApi:			Api.loadContacts,
			loadExtraContacts:		loadExtraContacts.bind(this, this),
			updateExtraContacts:	updateExtraContacts.bind(this, this),
			deleteContact:			deleteContact.bind(this, this),
			updateContact:			updateContact.bind(this, this)
		}
	}

	render() {

		let { classes } = this.props
		let showLoader = this.state.loading || this.state.appLoading
		// if (!this.state.appLoading) console.log('HIDE WELCOME LOADER') // If you see this log twice that menas that content got loaded after 5 seconds

		let callsApp = <Call
							screenViewType				= {this.state.screenViewType}
							extension					= {this.state.selectedExtension}
							contactsUtil				= {this.getContactsUtil()}
							onLoaded					= {this.onAppLoaded}
							updateUnreadCounts			= {this.setUnreadCounts}
							changeVoicemailReadStatus	= {this.changeVoicemailReadStatus}
							resetSubscription			= {this.resetSubscription}
							subscribeForNotifications	= {this.subscribeForNotifications}
							redirect					= {this.redirect}
						/>

		let messagesApp = <Messages
							screenViewType				= {this.state.screenViewType}
							extension					= {this.state.selectedExtension}
							contactsUtil				= {this.getContactsUtil()}
							onLoaded					= {this.onAppLoaded}
							updateUnreadCounts			= {this.setUnreadCounts}
							changeMessageReadStatus		= {this.changeMessageReadStatus}
							resetSubscription			= {this.resetSubscription}
							subscribeForNotifications	= {this.subscribeForNotifications}
							redirect					= {this.redirect}
						/>

		let voicemailsApp = <Voicemail
								screenViewType				= {this.state.screenViewType}
								extension					= {this.state.selectedExtension}
								contactsUtil				= {this.getContactsUtil()}
								onLoaded					= {this.onAppLoaded}
								updateUnreadCounts			= {this.setUnreadCounts}
								changeVoicemailReadStatus	= {this.changeVoicemailReadStatus}
								resetSubscription			= {this.resetSubscription}
								subscribeForNotifications	= {this.subscribeForNotifications}
								redirect					= {this.redirect}
							/>

		let faxesApp = <Faxes
							screenViewType				= {this.state.screenViewType}
							extension					= {this.state.selectedExtension}
							contactsUtil				= {this.getContactsUtil()}
							onLoaded					= {this.onAppLoaded}
							updateUnreadCounts			= {this.setUnreadCounts}
							changeFaxReadStatus			= {this.changeFaxReadStatus}
							resetSubscription			= {this.resetSubscription}
							subscribeForNotifications	= {this.subscribeForNotifications}
							redirect					= {this.redirect}
						/>

		let settingsApp = <Settings
							screenViewType				= {this.state.screenViewType}
							extension					= {this.state.selectedExtension}
							onLoaded					= {this.onAppLoaded}
							updateUnreadCounts			= {this.setUnreadCounts}
							changeMessageReadStatus		= {this.changeMessageReadStatus}
							resetSubscription			= {this.resetSubscription}
							subscribeForNotifications	= {this.subscribeForNotifications}
							redirect					= {this.redirect}
						/>

		return (
			<ThemeProvider theme={this.props.theme}>
				<div className={classes.mainDiv}>
					{this.state.showNotificationBar ? <NotificationBar hideBar={this.hideNotificationBar} /> : null}
					{this.state.userInfo && !this.state.loading ?
						<InfoPopup showOnceIdentifier={'first_my_phone_com'} content={this.infoPopupContent}/>
					: null}
					<AppLoader hidden={!showLoader} request={'Timing'}/>
					{/* Turn this state pass into context */}
					{this.state.userInfo && !this.state.loading && (<Router>
						<Navigation
							userInfo			= {this.state.userInfo}
							screenViewType		= {this.state.screenViewType}
							onExtensionSwitch	= {this.onExtensionSwitch}
							currentUser			= {this.state.selectedExtension}
							unreadMessages		= {this.state.unreadMessages}
							unreadVoicemails	= {this.state.unreadVoicemails}
							logout				= {this.logout}
							unreadFaxes			= {this.state.unreadFaxes}
							currentAppName		= {this.state.currentAppName}
						>
							<div style={{height:this.mobileAppBarSpacer()}}/>
							<div style={{height:this.getAppsHeight()}}>
								<Switch>
									<Route path={`/e${this.state.userInfo.extension_id}/messages/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.setState({redirectPath: null})
											return <Redirect to={path}/>
										}
										return this.returnApp(messagesApp, 'messages')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/voicemail/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.setState({redirectPath: null})
											return <Redirect to={path}/>
										}
										return this.returnApp(voicemailsApp, 'voicemail')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/calls/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.setState({redirectPath: null})
											return <Redirect to={path}/>
										}
										return this.returnApp(callsApp, 'calls')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/faxes/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.setState({redirectPath: null})
											return <Redirect to={path}/>
										}
										return this.returnApp(faxesApp, 'faxes')
									}} />
									<Route path={`/e${this.state.userInfo.extension_id}/settings/`} render={() => {
										if (this.state.redirectPath) {
											let path = this.state.redirectPath
											this.setState({redirectPath: null})
											return <Redirect to={path}/>
										}
										return this.returnApp(settingsApp, 'settings')
									}} />
									<Route path={`/`} render={() => {
										let pathnameSplit	= window.location.pathname.split('/')
										let appName			= pathnameSplit.slice(2, 3)[0] || theme.defaultRoute.replace('/','')
										switch (appName) {
											case 'calls':
												return this.returnApp(callsApp, 'calls')
											case 'messages':
												return this.returnApp(messagesApp, 'messages')
											case 'voicemail':
												return this.returnApp(voicemailsApp, 'voicemails')
											case 'faxes':
												return this.returnApp(faxesApp, 'faxes')
											case 'settings':
												return this.returnApp(settingsApp, 'settings')
										}
									}} />
								</Switch>
							</div>
						</Navigation>
					</Router>)}
				</div>
			</ThemeProvider>
		)
	}
}

export default withStyles(styles)(Communicator)