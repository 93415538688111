import React, {useState} from 'react'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import ProfileMenu from './ProfileMenu'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography/Typography'
import IconButton from '@material-ui/core/IconButton/IconButton'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import AppBar from '@material-ui/core/AppBar/AppBar'
import {
	CallsNavIcon,
	MessagesNavIcon,
	VoicemailNavIcon,
	FaxesNavIcon,
	ControlPanelNavIcon,
	AppsNavIcon,
	SupportNavIcon,
	LogoutNavIcon,
	SettingsNavIcon
} from 'pdc-svg-icons'
import { theme } from 'get-theme'
import {logout} from "phonecom";

const useStyles = makeStyles(theme => ({
	root: {
		display:	'flex',
		position:	'relative',
		height:		'100%',
		minHeight:	0
	},
	drawableContent: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	drawerWrapper: {
		display:		'flex',
		flexDirection:	'column',
		justifyContent:	'space-between',
		height:			'100%',
		overflowY:		'auto'
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: theme.drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: theme.appBar,
	menuButton: {
		marginRight:	theme.spacing(2),
		outline:		'none !important'
	},
	toolbar: {
		minHeight: 56
	},
	drawerPaper: theme.drawerPaper,
	content: {
		height:		'100%',
		maxWidth:	'100%',
		flexGrow:	1
	},
	drawerList: {
		paddingLeft: '10px',
		paddingRight: '10px'
	},
	drawIcon: {
		color: theme.drawerPaper.color,
		width: 35
	},
	drawerItem: {
		marginBottom: '2px',
		'&:not(.active):hover': {backgroundColor: 'rgba(136, 150, 158, 0.35)'},
		'&.active': {backgroundColor: 'rgba(136, 150, 158, 0.8)'},
		'&.separate': {marginTop: 42}
	},
	disabledLabel: {
		marginTop: '-10px',
		fontSize: theme.tinyText.fontSize,
		color: theme.disabledLabel.color
	},
	appLinkStyle: {
		textDecoration: 'none',
		color: theme.drawerPaper.color,
		'&:hover': {
			textDecoration: 'none',
			color: theme.drawerPaper.color,
		}
	},
	unreadIcon: {
		backgroundColor: theme.notificationDotColor,
		width: '10px',
		height: '10px',
		borderRadius: '50%'
	},
	ghostPDCIcon: theme.navigation.ghostIcon
}))

// This may need to be stateful
export default function Navigation(props) {

	const { container }	= props
	const userInfo		= props.userInfo
	const classes		= useStyles()
	const theme			= useTheme()
	const isMobileView	= props.screenViewType.isMobileView
	const isTabletView	= props.screenViewType.isTabletView
	const [mobileOpen, setMobileOpen] = React.useState(false)
	const cfg			= window.APP_CONFIG
	const session		= userInfo.cp_session_token
	const cpBase		= cfg.cpBase
	const appName		= props.currentAppName
	const appTitles 	= {
		calls:			'Calls',
		messages:		'Messages',
		voicemail:		'Voicemail',
		faxes:			'Fax',
		controlPanel:	'Control Panel',
		meetings:		'Meetings',
		settings:		'Settings',
		apps:			'Apps',
		support:		'Support',
		logout:			'Logout'
	}

	const callsTab = {
		testId:		'calls-tab',
		link:		`/e${userInfo.extension_id}/calls`,
		icon:		CallsNavIcon,
		text:		appTitles.calls,
		enabled:	!process.env.REACT_APP_CALLS_TAB_HIDDEN,
		unread:		props.unreadCalls,
		active:		appName === 'calls',
	}

	const messageTab = {
		link:		`/e${userInfo.extension_id}/messages`,
		icon:		MessagesNavIcon,
		text:		appTitles.messages,
		enabled:	!process.env.REACT_APP_MESSAGES_TAB_HIDDEN,
		unread:		props.unreadMessages,
		active:		appName === 'messages',
	    testId:		'messages-tab',
	}

	const voicemailTab = {
		link:		`/e${userInfo.extension_id}/voicemail`,
		icon:		VoicemailNavIcon,
		text:		appTitles.voicemail,
		enabled:	!process.env.REACT_APP_VOICEMAIL_TAB_HIDDEN,
		unread:		props.unreadVoicemails,
		active:		appName === 'voicemail',
		testId:		'voicemail-tab'
	}
	
	const faxesTab = {
		link:		`/e${userInfo.extension_id}/faxes`,
		icon:		FaxesNavIcon,
		text:		appTitles.faxes,
		enabled:	!process.env.REACT_APP_FAX_TAB_HIDDEN,
		unread:		props.unreadFaxes,
		active:		appName === 'faxes',
		testId:		'faxes-tab'
	}
	const controlPanelTab = {
		testId:		`control-panel-tab`,
		link:		`${cpBase}/voip/?_sid=${session}`,
		icon:		ControlPanelNavIcon,
		text:		appTitles.controlPanel,
		enabled:	!process.env.REACT_APP_CONTROL_PANEL_TAB_HIDDEN
	}

	const meetTab = {
		testId:		`meet-tab`,
		link:		'https://phonedotcom.callbridge.com/conf/call/[id]',
		icon:		ControlPanelNavIcon,
		text:		appTitles.meetings,
		enabled:	!process.env.REACT_APP_CONTROL_PANEL_TAB_HIDDEN
	}

	const settingsTab = {
		link:		`/e${userInfo.extension_id}/settings`,
		icon:		SettingsNavIcon,
		text:		appTitles.settings,
		enabled:	!process.env.REACT_APP_SETTINGS_TAB_HIDDEN,
		active:		appName === 'settings'
	}

	const appsTab = {
		testId:		`apps-tab`,
		link:		theme.appsTabLink,
		icon:		AppsNavIcon,
		text:		appTitles.apps,
		enabled:	!process.env.REACT_APP_APPS_TAB_HIDDEN
	}

	const supportTab = {
		testId:		`support-tab`,
		link:		theme.supportTabLink,
		icon:		SupportNavIcon,
		text:		appTitles.support,
		enabled:	!process.env.REACT_APP_SUPPORT_TAB_HIDDEN
	}

	const logoutTab = {
		testId:		`support-tab`,
		link:		`${window.V5PHONECOM.v5ApiRoot.replace('services', 'app')}/communicator/account/logout/?account_id=${window.V5PHONECOM.voip_id}&extension_id=${window.V5PHONECOM.voip_phone_id}`,
		icon:		LogoutNavIcon,
		text:		appTitles.logout,
		enabled:	!process.env.REACT_APP_LOGOUT_TAB_HIDDEN
	}

	let routerConfig = [callsTab, messageTab, voicemailTab, faxesTab, meetTab, settingsTab, controlPanelTab, appsTab, supportTab, logoutTab]
    // if (window.V5PHONECOM.features.has('fax')){
    //     routerConfig = [messageTab, voicemailTab, faxesTab, controlPanelTab]
    // }
    
	const [appBarLabel, setAppBarLabel] = useState(getInitialAppBarLabel)

	function getInitialAppBarLabel() {
		for (let index in routerConfig) {
			let route = routerConfig[index]
			if (window.location.pathname.indexOf(route['link']) !== -1) {
				return route['text']
			}
		}
		return 'Calls'
	}

	function handleDrawerToggle() {
		if (isMobileView || isTabletView) {
			setMobileOpen(!mobileOpen)
		}
	}

	function extensionSelectHandler(item, i) {
		props.onExtensionSwitch(item)
		if ((isMobileView || isTabletView) && mobileOpen) handleDrawerToggle()
	}

	function getAppBar() {
		if (!isMobileView && !isTabletView) return null
		return (
			<AppBar className={classes.appBar}>
				<Toolbar classes={{root: classes.toolbar}}>
				<IconButton
					color		= 'inherit'
					aria-label	= 'Open drawer'
					edge		= 'start'
					onClick		= {handleDrawerToggle}
					className	= {classes.menuButton}
				>
					<MenuIcon/>
				</IconButton>
				<Typography variant='h6' noWrap>
					{appBarLabel}
				</Typography>
				</Toolbar>
			</AppBar>
		)
	}

	function handleMenuChange(route) {
		if (!route.enabled) return
		handleDrawerToggle()
		if (!checkPopout(route))
			setAppBarLabel(route.text)
	}

	function checkPopout(route) {
		if (route.link.indexOf('https') !== -1) {
			if (route.text === 'Logout') {
				logout()
			} else {
				window.open(route.link,route.text)
			}
			return true
		}
		return false
	}

	function setTitle() {
		window.document.title = `${(appTitles[appName] || '')} - ${(theme.appName || 'My Phone.com')}`
	}

	function resetAppBarLabel() {
		let selectedAppBarLabel = getInitialAppBarLabel()
		if (appBarLabel !== selectedAppBarLabel) setAppBarLabel(selectedAppBarLabel)
	}
	
	setTitle()
	resetAppBarLabel()

	const drawer = (
		<div className={classes.drawerWrapper}>
			<List className={classes.drawerList}>
				{routerConfig.map((route, index) => {
					if (!route.enabled) return
					let to		= (route.link.indexOf('http') === -1)? route.link : '#'
					let active	= route.active
					let Icon	= route.icon
					return (
						<Link
						    data-test-id= {route.testId}
							key			= {route.text}
							to			= {to}
							onClick		= {() => handleMenuChange(route)}
							className	= {classes.appLinkStyle}
						>
							<ListItem
								button
								className={`${classes.drawerItem} ${active ? 'active' : ''} ${route.text === 'Control Panel' ? 'separate' : ''}`}
							>

								<ListItemIcon>
									<Icon active={route.active ? 1 : 0} read={!route.unread ? 1 : 0} className={classes.drawIcon}/>
								</ListItemIcon>

								<ListItemText primary={route.text} />
								{!route.enabled && <span className={classes.disabledLabel}>Coming Soon!</span>}

							</ListItem>
						</Link>
					)
				})}
			</List>
			<div className={classes.ghostPDCIcon}/>
		</div>
	)

	const getDrawerContent = (
		<div className={classes.drawableContent}>
			<ProfileMenu
				userInfo				= {userInfo}
				currentUser				= {props.currentUser}
				extensionSelectHandler	= {extensionSelectHandler}
				screenViewType			= {props.screenViewType}
				logout					= {props.logout}
			/>
			{drawer}
		</div>
	)

	const mobileDrawer = (
		<div>
			<SwipeableDrawer
				onOpen		= {handleDrawerToggle}
				container	= {container}
				variant		= 'temporary'
				anchor		= {theme.direction === 'rtl' ? 'right' : 'left'}
				open		= {mobileOpen}
				onClose		= {handleDrawerToggle}
				classes		= {{
					paper: classes.drawerPaper,
				}}
				ModalProps	= {{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				{getDrawerContent}
			</SwipeableDrawer>
		</div>
	)

	const desktopDrawer = (
		<div>
			<div style={{width:theme.drawerWidth}}/>
			<SwipeableDrawer
				classes	= {{
					paper: classes.drawerPaper,
				}}
				variant	= 'permanent'
				onOpen	= {() => {}}
				onClose	= {() => {}}
				open
			>
				{getDrawerContent}
			</SwipeableDrawer>
		</div>
	)

	function getDrawer() {
		if (isMobileView || isTabletView) {
			return mobileDrawer
		}
		return desktopDrawer
	}

	return (
		<div className={classes.root}>
			{getAppBar()}
			{getDrawer()}
			<main className={classes.content}>
				{props.children}
			</main>
		</div>
	)
}